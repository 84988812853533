<template>
  <div v-if="!isLoadingAll()">
    <div v-if="infoText != null" class="row">
      <div class="col-12 text-center">
        <h2 class="">{{ infoText }}</h2>
      </div>
    </div>
    <form
      v-if="infoText == null"
      class="fragebogen-form mt-3 mb-3"
      name="onlineFragebogenForm"
      novalidate
      role="form"
      v-on:submit.prevent=""
    >
      <div class="row">
        <div class="col-7">
          <h2 id="care4YouApp.questionnaire.home.createLabel" v-text="$t('care4YouApp.fragebogen.home.title')">Fragebogen</h2>
        </div>
        <div class="col-5 text-right">
          <img
            v-if="agencyLogoImageType"
            alt="agency logo"
            style="max-height: 96px"
            v-bind:src="'data:' + agencyLogoImageType + ';base64,' + agencyLogoImageData"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p v-text="$t('care4YouApp.fragebogen.home.header')">Header</p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-2">
          <span class="font-weight-bold" v-text="$t('care4YouApp.fragebogen.home.perMail')">per Post</span><br />
          <span v-if="hasFax()" class="font-weight-bold" v-text="$t('care4YouApp.fragebogen.home.perFax')">per Fax</span
          ><br v-if="hasFax()" />
          <span class="font-weight-bold" v-text="$t('care4YouApp.fragebogen.home.perEMail')">per Mail</span><br />
        </div>
        <div class="col-10">
          <span v-text="getAnschrift()">Anschrift</span><br />
          <span v-if="hasFax()" v-text="getFax()">Fax</span><br v-if="hasFax()" />
          <span v-text="getMail()">Mail</span>
        </div>
      </div>
      <br />
      <h2 v-text="$t('care4YouApp.fragebogen.sections.begin')">Betreuungsbeginn</h2>

      <div class="row border border-dark">
        <div class="col-6">
          <b-form-group
            id="fieldset-wunschdatum1"
            :label="$t('care4YouApp.fragebogen.fields.wunschdatum1') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="wunschdatum1"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  v-model="getQuestion(attributes, QUESTION_WUNSCHDATUM1).value"
                  :locale="currentLanguage"
                  aria-controls="wunschdatum1"
                  button-only
                  class="form-control"
                  close-button
                  name="wunschdatum1"
                  reset-button
                  today-button
                  :disabled="readonlyMode"
                >
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="wunschdatum1"
                :value="convertDateFromServer(getQuestion(attributes, QUESTION_WUNSCHDATUM1).value)"
                class="form-control text-center"
                name="wunschdatum1"
                :state="validateValue(QUESTION_WUNSCHDATUM1)"
                style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
                type="text"
                :disabled="readonlyMode"
                @change="updateDateField($event, getQuestion(attributes, QUESTION_WUNSCHDATUM1), 'value')"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            id="fieldset-wunschdatum2"
            :label="$t('care4YouApp.fragebogen.fields.wunschdatum2')"
            content-cols="8"
            label-cols="4"
            label-for="wunschdatum2"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  v-model="getQuestion(attributes, QUESTION_WUNSCHDATUM2).value"
                  :locale="currentLanguage"
                  aria-controls="wunschdatum2"
                  button-only
                  class="form-control text-center"
                  close-button
                  name="wunschdatum2"
                  reset-button
                  today-button
                  :disabled="readonlyMode"
                >
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="wunschdatum2"
                :value="convertDateFromServer(getQuestion(attributes, QUESTION_WUNSCHDATUM2).value)"
                class="form-control text-center"
                name="wunschdatum2"
                :state="validateValue(QUESTION_WUNSCHDATUM2)"
                style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
                type="text"
                :disabled="readonlyMode"
                @change="updateDateField($event, getQuestion(attributes, QUESTION_WUNSCHDATUM2), 'value')"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <br />
      <h2 v-text="$t('care4YouApp.fragebogen.sections.contactPerson') + getObligatorySign()">Ansprechpartner</h2>
      <div class="row">
        <div class="col-6">
          <b-form-group
            id="fieldset-ehepartner"
            :label="$t('care4YouApp.fragebogen.fields.ehepartner')"
            content-cols="8"
            label-cols="4"
            label-for="ehepartner"
            :style="getLabelStyleByValidateValue(QUESTION_ANSPRECHPARTNER)"
          >
            <input
              id="ehepartner"
              v-model="ansprechpartnerEhepartner"
              class="form-control"
              name="ehepartner"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              :disabled="readonlyMode"
              type="checkbox"
              @change="onAnsprechpartnerChange('ehepartner')"
            />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            id="fieldset-schwager"
            :label="$t('care4YouApp.fragebogen.fields.schwager')"
            content-cols="8"
            label-cols="4"
            label-for="schwager"
            :style="getLabelStyleByValidateValue(QUESTION_ANSPRECHPARTNER)"
          >
            <input
              id="schwager"
              v-model="ansprechpartnerSchwager"
              class="form-control"
              name="schwager"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
              @change="onAnsprechpartnerChange('schwager')"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <b-form-group
            id="fieldset-sohn"
            :label="$t('care4YouApp.fragebogen.fields.sohn')"
            content-cols="8"
            label-cols="4"
            label-for="sohn"
            :style="getLabelStyleByValidateValue(QUESTION_ANSPRECHPARTNER)"
          >
            <input
              id="sohn"
              v-model="ansprechpartnerSohn"
              :disabled="readonlyMode"
              class="form-control"
              name="sohn"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              @change="onAnsprechpartnerChange('kind')"
            />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            id="fieldset-sonstige"
            :label="$t('care4YouApp.fragebogen.fields.sonstige')"
            content-cols="8"
            label-cols="4"
            label-for="sonstige"
            :style="getLabelStyleByValidateValue(QUESTION_ANSPRECHPARTNER)"
          >
            <input
              id="sonstige"
              v-model="ansprechpartnerSonstige"
              class="form-control"
              name="sonstige"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
              @beforeinput="onAnsprechpartnerChange('sonstige')"
              @change="onAnsprechpartnerChange('sonstige')"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-ansprechpartner_name"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_name') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_name"
          >
            <b-form-input
              id="ansprechpartner_name"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_NAME).value"
              class="form-control"
              name="ansprechpartner_name"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_NAME)"
              :disabled="readonlyMode"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-ansprechpartner_vorname"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_vorname') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_vorname"
          >
            <b-form-input
              id="ansprechpartner_vorname"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_VORNAME).value"
              class="form-control"
              name="ansprechpartner_vorname"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_VORNAME)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-ansprechpartner_strasse"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_strasse') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_strasse"
          >
            <b-form-input
              id="ansprechpartner_strasse"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_STRASSE).value"
              class="form-control"
              name="ansprechpartner_strasse"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_STRASSE)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-ansprechpartner_hausnummer"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_hausnummer') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_hausnummer"
          >
            <b-form-input
              id="ansprechpartner_hausnummer"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_HAUSNUMMER).value"
              class="form-control"
              name="ansprechpartner_hausnummer"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_HAUSNUMMER)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-ansprechpartner_plz"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_plz') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_plz"
          >
            <b-form-input
              id="ansprechpartner_plz"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_PLZ).value"
              class="form-control"
              name="ansprechpartner_plz"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_PLZ)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-ansprechpartner_ort"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_ort') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_ort"
          >
            <b-form-input
              id="ansprechpartner_ort"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_ORT).value"
              class="form-control"
              name="ansprechpartner_ort"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_ORT)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark">
          <b-form-group
            id="fieldset-ansprechpartner_rufnummer"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_rufnummer') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_rufnummer"
          >
            <b-form-input
              id="ansprechpartner_rufnummer"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_TELEFON).value"
              class="form-control"
              name="ansprechpartner_rufnummer"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_TELEFON)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-left-0">
          <b-form-group
            id="fieldset-ansprechpartner_mail"
            :label="$t('care4YouApp.fragebogen.fields.ansprechpartner_mail') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="ansprechpartner_mail"
          >
            <b-form-input
              id="ansprechpartner_mail"
              v-model="getQuestion(attributes, QUESTION_ANSPRECHPARTNER_MAIL).value"
              class="form-control"
              name="ansprechpartner_mail"
              :state="validateValue(QUESTION_ANSPRECHPARTNER_MAIL)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <h2 v-text="$t('care4YouApp.fragebogen.sections.patient')">Patient</h2>

      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-patient_name"
            :label="$t('care4YouApp.fragebogen.fields.patient_name') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_name"
          >
            <b-form-input
              id="patient_name"
              v-model="getQuestion(attributes, QUESTION_PATIENT_NAME).value"
              class="form-control"
              name="patient_name"
              :state="validateValue(QUESTION_PATIENT_NAME)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-patient_vorname"
            :label="$t('care4YouApp.fragebogen.fields.patient_vorname') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_vorname"
          >
            <b-form-input
              id="patient_vorname"
              v-model="getQuestion(attributes, QUESTION_PATIENT_VORNAME).value"
              class="form-control"
              name="patient_vorname"
              :state="validateValue(QUESTION_PATIENT_VORNAME)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-patient_strasse"
            :label="$t('care4YouApp.fragebogen.fields.patient_strasse') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_strasse"
          >
            <b-form-input
              id="patient_strasse"
              v-model="getQuestion(attributes, QUESTION_PATIENT_STRASSE).value"
              class="form-control"
              name="patient_strasse"
              :state="validateValue(QUESTION_PATIENT_STRASSE)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-patient_hausnummer"
            :label="$t('care4YouApp.fragebogen.fields.patient_hausnummer') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_hausnummer"
          >
            <b-form-input
              id="patient_hausnummer"
              v-model="getQuestion(attributes, QUESTION_PATIENT_HAUSNUMMER).value"
              class="form-control"
              name="patient_hausnummer"
              :state="validateValue(QUESTION_PATIENT_HAUSNUMMER)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-patient_plz"
            :label="$t('care4YouApp.fragebogen.fields.patient_plz') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_plz"
          >
            <b-form-input
              id="patient_plz"
              v-model="getQuestion(attributes, QUESTION_PATIENT_PLZ).value"
              class="form-control"
              name="patient_plz"
              :state="validateValue(QUESTION_PATIENT_PLZ)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-patient_ort"
            :label="$t('care4YouApp.fragebogen.fields.patient_ort') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_ort"
          >
            <b-form-input
              id="patient_ort"
              v-model="getQuestion(attributes, QUESTION_PATIENT_ORT).value"
              class="form-control"
              name="patient_ort"
              :state="validateValue(QUESTION_PATIENT_ORT)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark border-bottom-0">
          <b-form-group
            id="fieldset-patient_krankenkasse"
            :label="$t('care4YouApp.fragebogen.fields.patient_krankenkasse') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_krankenkasse"
          >
            <b-form-input
              id="patient_krankenkasse"
              v-model="getQuestion(attributes, QUESTION_PATIENT_KRANKENKASSE).value"
              :disabled="readonlyMode"
              class="form-control"
              name="patient_krankenkasse"
              :state="validateValue(QUESTION_PATIENT_KRANKENKASSE)"
              required
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-bottom-0 border-left-0">
          <b-form-group
            id="fieldset-patient_geburtsdatum"
            :label="$t('care4YouApp.fragebogen.fields.patient_geburtsdatum') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_geburtsdatum"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  v-model="getQuestion(attributes, QUESTION_PATIENT_GEBURTSDATUM).value"
                  :locale="currentLanguage"
                  aria-controls="patient_geburtsdatum"
                  button-only
                  class="form-control"
                  close-button
                  name="patient_geburtsdatum"
                  reset-button
                  today-button
                  :disabled="readonlyMode"
                >
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="patient_geburtsdatum"
                :value="convertDateFromServer(getQuestion(attributes, QUESTION_PATIENT_GEBURTSDATUM).value)"
                class="form-control text-center"
                name="patient_geburtsdatum"
                :state="validateValue(QUESTION_PATIENT_GEBURTSDATUM)"
                style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
                type="text"
                :disabled="readonlyMode"
                @change="updateDateField($event, getQuestion(attributes, QUESTION_PATIENT_GEBURTSDATUM), 'value')"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6 border border-dark">
          <b-form-group
            id="fieldset-patient_groesse"
            :label="$t('care4YouApp.fragebogen.fields.patient_groesse') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_groesse"
          >
            <b-form-input
              id="patient_groesse"
              v-model="getQuestion(attributes, QUESTION_PATIENT_GROESSE).value"
              class="form-control"
              name="patient_groesse"
              :state="validateValue(QUESTION_PATIENT_GROESSE)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="number"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-left-0">
          <b-form-group
            id="fieldset-patient_gewicht"
            :label="$t('care4YouApp.fragebogen.fields.patient_gewicht') + getObligatorySign()"
            content-cols="8"
            label-cols="4"
            label-for="patient_gewicht"
          >
            <b-form-input
              id="patient_gewicht"
              v-model="getQuestion(attributes, QUESTION_PATIENT_GEWICHT).value"
              class="form-control"
              name="patient_gewicht"
              :state="validateValue(QUESTION_PATIENT_GEWICHT)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="number"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-6 border border-dark">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.pflegegrad') + getObligatorySign()">Pflegegrad</h3>
          <b-form-group
            id="fieldset-pflegegrad_0"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD0')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_0"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_0"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="0"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-pflegegrad_1"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD1')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_1"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_1"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="1"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-pflegegrad_2"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD2')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_2"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_2"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="2"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-pflegegrad_3"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD3')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_3"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_3"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="3"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-pflegegrad_4"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD4')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_4"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_4"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="4"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-pflegegrad_5"
            :label="$t('care4YouApp.FragePflegegrad.PFLEGEGRAD5')"
            content-cols="8"
            label-cols="4"
            label-for="pflegegrad_5"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGEGRAD)"
          >
            <input
              id="pflegegrad_5"
              v-model="getQuestion(attributes, QUESTION_PFLEGEGRAD).value"
              class="form-control"
              name="pflegegrad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="5"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-left-0">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.diagnosen')">Diagnosen</h3>
          <b-form-group
            id="fieldset-diagnose_altersschwaeche"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_altersschwaeche')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_altersschwaeche"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_ALTERSSCHWAECHE)"
          >
            <input
              id="diagnose_altersschwaeche"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_ALTERSSCHWAECHE).value"
              class="form-control"
              name="diagnose_altersschwaeche"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-diagnose_demenz"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_demenz')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_demenz"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_DEMENZ)"
          >
            <input
              id="diagnose_demenz"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_DEMENZ).value"
              class="form-control"
              name="diagnose_demenz"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-diagnose_krebs"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_krebs')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_krebs"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_KREBS)"
          >
            <input
              id="diagnose_krebs"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_KREBS).value"
              class="form-control"
              name="diagnose_krebs"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-diagnose_schlaganfall"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_schlaganfall')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_schlaganfall"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_SCHLAGANFALL)"
          >
            <input
              id="diagnose_schlaganfall"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_SCHLAGANFALL).value"
              class="form-control"
              name="diagnose_schlaganfall"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-diagnose_einsamkeit"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_einsamkeit')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_einsamkeit"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_EINSAMKEIT)"
          >
            <input
              id="diagnose_einsamkeit"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_EINSAMKEIT).value"
              class="form-control"
              name="diagnose_einsamkeit"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-diagnose_angstzustaende"
            :label="$t('care4YouApp.fragebogen.fields.diagnose_angstzustaende')"
            content-cols="8"
            label-cols="4"
            label-for="diagnose_angstzustaende"
            :style="getLabelStyleByValidateValue(QUESTION_DIAGNOSE_ANGSTZUSTAENDE)"
          >
            <input
              id="diagnose_angstzustaende"
              v-model="getQuestion(attributes, QUESTION_DIAGNOSE_ANGSTZUSTAENDE).value"
              class="form-control"
              name="diagnose_angstzustaende"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-6 border border-dark">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.vorhandene_hilfsmittel')">Vorhandene Hilfsmittel</h3>
          <b-form-group
            id="fieldset-hilfsmittel_rollstuhl"
            :label="$t('care4YouApp.fragebogen.fields.hilfsmittel_rollstuhl')"
            content-cols="8"
            label-cols="4"
            label-for="hilfsmittel_rollstuhl"
            :style="getLabelStyleByValidateValue(QUESTION_HILFSMITTEL_ROLLSTUHL)"
          >
            <input
              id="hilfsmittel_rollstuhl"
              v-model="getQuestion(attributes, QUESTION_HILFSMITTEL_ROLLSTUHL).value"
              class="form-control"
              name="hilfsmittel_rollstuhl"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-hilfsmittel_rollator"
            :label="$t('care4YouApp.fragebogen.fields.hilfsmittel_rollator')"
            content-cols="8"
            label-cols="4"
            label-for="hilfsmittel_rollator"
            :style="getLabelStyleByValidateValue(QUESTION_HILFSMITTEL_ROLLATOR)"
          >
            <input
              id="hilfsmittel_rollator"
              v-model="getQuestion(attributes, QUESTION_HILFSMITTEL_ROLLATOR).value"
              class="form-control"
              name="hilfsmittel_rollator"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-hilfsmittel_treppenlift"
            :label="$t('care4YouApp.fragebogen.fields.hilfsmittel_treppenlift')"
            content-cols="8"
            label-cols="4"
            label-for="hilfsmittel_treppenlift"
            :style="getLabelStyleByValidateValue(QUESTION_HILFSMITTEL_TREPPENLIFT)"
          >
            <input
              id="hilfsmittel_treppenlift"
              v-model="getQuestion(attributes, QUESTION_HILFSMITTEL_TREPPENLIFT).value"
              class="form-control"
              name="hilfsmittel_treppenlift"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-hilfsmittel_patientenlifter"
            :label="$t('care4YouApp.fragebogen.fields.hilfsmittel_patientenlifter')"
            content-cols="8"
            label-cols="4"
            label-for="hilfsmittel_patientenlifter"
            :style="getLabelStyleByValidateValue(QUESTION_HILFSMITTEL_PATIENTENLIFTER)"
          >
            <input
              id="hilfsmittel_patientenlifter"
              v-model="getQuestion(attributes, QUESTION_HILFSMITTEL_PATIENTENLIFTER).value"
              class="form-control"
              name="hilfsmittel_patientenlifter"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-hilfsmittel_pflegebett"
            :label="$t('care4YouApp.fragebogen.fields.hilfsmittel_pflegebett')"
            content-cols="8"
            label-cols="4"
            label-for="hilfsmittel_pflegebett"
            :style="getLabelStyleByValidateValue(QUESTION_HILFSMITTEL_PFLEGEBETT)"
          >
            <input
              id="hilfsmittel_pflegebett"
              v-model="getQuestion(attributes, QUESTION_HILFSMITTEL_PFLEGEBETT).value"
              class="form-control"
              name="hilfsmittel_pflegebett"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-left-0">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.zustand')">Zustand</h3>
          <b-form-group
            id="fieldset-zustand_bettlaegerig"
            :label="$t('care4YouApp.fragebogen.fields.zustand_bettlaegerig')"
            content-cols="8"
            label-cols="4"
            label-for="zustand_bettlaegerig"
            :style="getLabelStyleByValidateValue(QUESTION_ZUSTAND_BETTLAEGERIG)"
          >
            <input
              id="zustand_bettlaegerig"
              v-model="getQuestion(attributes, QUESTION_ZUSTAND_BETTLAEGERIG).value"
              class="form-control"
              name="zustand_bettlaegerig"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-zustand_hilfe_stehen"
            :label="$t('care4YouApp.fragebogen.fields.zustand_hilfe_stehen')"
            content-cols="8"
            label-cols="4"
            label-for="zustand_hilfe_stehen"
            :style="getLabelStyleByValidateValue(QUESTION_ZUSTAND_HILFESTEHEN)"
          >
            <input
              id="zustand_hilfe_stehen"
              v-model="getQuestion(attributes, QUESTION_ZUSTAND_HILFESTEHEN).value"
              class="form-control"
              name="zustand_hilfe_stehen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-zustand_hilfe_gehen"
            :label="$t('care4YouApp.fragebogen.fields.zustand_hilfe_gehen')"
            content-cols="8"
            label-cols="4"
            label-for="zustand_hilfe_gehen"
            :style="getLabelStyleByValidateValue(QUESTION_ZUSTAND_HILFEGEHEN)"
          >
            <input
              id="zustand_hilfe_gehen"
              v-model="getQuestion(attributes, QUESTION_ZUSTAND_HILFEGEHEN).value"
              class="form-control"
              name="zustand_hilfe_gehen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-zustand_sehen"
            :label="$t('care4YouApp.fragebogen.fields.zustand_sehen')"
            content-cols="8"
            label-cols="4"
            label-for="zustand_sehen"
            :style="getLabelStyleByValidateValue(QUESTION_ZUSTAND_EINGECHRAENKTSEHEN)"
          >
            <input
              id="zustand_sehen"
              v-model="getQuestion(attributes, QUESTION_ZUSTAND_EINGECHRAENKTSEHEN).value"
              class="form-control"
              name="zustand_sehen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-zustand_hoeren"
            :label="$t('care4YouApp.fragebogen.fields.zustand_hoeren')"
            content-cols="8"
            label-cols="4"
            label-for="zustand_hoeren"
            :style="getLabelStyleByValidateValue(QUESTION_ZUSTAND_EINGECHRAENKTHOEREN)"
          >
            <input
              id="zustand_hoeren"
              v-model="getQuestion(attributes, QUESTION_ZUSTAND_EINGECHRAENKTHOEREN).value"
              class="form-control"
              name="zustand_hoeren"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />
      <h2 v-text="$t('care4YouApp.fragebogen.sections.careDoings')">Grundpflegerische- und Betreuungstätigkeiten</h2>
      <div class="row">
        <div class="col-6 border border-dark">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.grundpflegerische_taetigkeiten')">Grundpflegerische Tätigkeiten</h3>
          <b-form-group
            id="fieldset-koerperwaesche"
            :label="$t('care4YouApp.fragebogen.fields.koerperwaesche')"
            content-cols="8"
            label-cols="4"
            label-for="koerperwaesche"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE)"
          >
            <input
              id="koerperwaesche"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_KOERPERWAESCHE).value"
              class="form-control"
              name="koerperwaesche"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-toilettengang"
            :label="$t('care4YouApp.fragebogen.fields.toilettengang')"
            content-cols="8"
            label-cols="4"
            label-for="toilettengang"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG)"
          >
            <input
              id="toilettengang"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_TOILETTENGANG).value"
              class="form-control"
              name="toilettengang"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-an_und_auskleiden"
            :label="$t('care4YouApp.fragebogen.fields.an_und_auskleiden')"
            content-cols="8"
            label-cols="4"
            label-for="an_und_auskleiden"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_KLEIDEN)"
          >
            <input
              id="an_und_auskleiden"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_KLEIDEN).value"
              class="form-control"
              name="an_und_auskleiden"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-intimpflege"
            :label="$t('care4YouApp.fragebogen.fields.intimpflege')"
            content-cols="8"
            label-cols="4"
            label-for="intimpflege"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE)"
          >
            <input
              id="intimpflege"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_INTIMPFLEGE).value"
              class="form-control"
              name="intimpflege"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-trinkueberwachung"
            :label="$t('care4YouApp.fragebogen.fields.trinkueberwachung')"
            content-cols="8"
            label-cols="4"
            label-for="trinkueberwachung"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG)"
          >
            <input
              id="trinkueberwachung"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_TRINKUEBERWACHUNG).value"
              class="form-control"
              name="trinkueberwachung"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-hilfe_nahrungsaufnahme"
            :label="$t('care4YouApp.fragebogen.fields.hilfe_nahrungsaufnahme')"
            content-cols="8"
            label-cols="4"
            label-for="hilfe_nahrungsaufnahme"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG)"
          >
            <input
              id="hilfe_nahrungsaufnahme"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_HILFENAHRUNG).value"
              class="form-control"
              name="hilfe_nahrungsaufnahme"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-6 border border-dark border-left-0">
          <h3 v-text="$t('care4YouApp.fragebogen.fields.betreuungstaetigkeiten')">Betreuungstätigkeiten</h3>
          <b-form-group
            id="fieldset-spazierengehen"
            :label="$t('care4YouApp.fragebogen.fields.spazierengehen')"
            content-cols="8"
            label-cols="4"
            label-for="spazierengehen"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN)"
          >
            <input
              id="spazierengehen"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SPAZIEREN).value"
              class="form-control"
              name="spazierengehen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-handarbeit"
            :label="$t('care4YouApp.fragebogen.fields.handarbeit')"
            content-cols="8"
            label-cols="4"
            label-for="handarbeit"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT)"
          >
            <input
              id="handarbeit"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_HANDARBEIT).value"
              class="form-control"
              name="handarbeit"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-spiele_spielen"
            :label="$t('care4YouApp.fragebogen.fields.spiele_spielen')"
            content-cols="8"
            label-cols="4"
            label-for="spiele_spielen"
            :style="getLabelStyleByValidateValue(QUESTION_PFLEGETAETIGKEITEN_SPIELEN)"
          >
            <input
              id="spiele_spielen"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SPIELEN).value"
              class="form-control"
              name="spiele_spielen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-betreuungstaetigkeiten_sonstige"
            :label="$t('care4YouApp.fragebogen.fields.betreuungstaetigkeiten_sonstige')"
            content-cols="8"
            label-cols="4"
            label-for="betreuungstaetigkeiten_sonstige"
          >
            <input
              id="betreuungstaetigkeiten_sonstige"
              v-model="getQuestion(attributes, QUESTION_PFLEGETAETIGKEITEN_SONSTIGES).value"
              class="form-control"
              name="betreuungstaetigkeiten_sonstige"
              :state="validateValue(QUESTION_PFLEGETAETIGKEITEN_SONSTIGES)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />
      <h2 v-text="$t('care4YouApp.fragebogen.sections.householdDoings')">Haushaltstätigkeiten</h2>
      <div class="row">
        <div class="col-12 border border-dark">
          <b-form-group
            id="fieldset-kochen_fuer_personen"
            :label="$t('care4YouApp.fragebogen.fields.kochen_fuer_personen') + getObligatorySign()"
            content-cols="4"
            label-cols="4"
            label-for="kochen_fuer_personen"
          >
            <b-form-input
              id="kochen_fuer_personen"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_KOCHEN).value"
              class="form-control"
              name="kochen_fuer_personen"
              :state="validateValue(QUESTION_HAUSHALT_KOCHEN)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="number"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-waschen_putzen_buegeln"
            :label="$t('care4YouApp.fragebogen.fields.waschen_putzen_buegeln')"
            content-cols="4"
            label-cols="4"
            label-for="waschen_putzen_buegeln"
            :style="getLabelStyleByValidateValue(QUESTION_HAUSHALT_WASCHEN)"
          >
            <input
              id="waschen_putzen_buegeln"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_WASCHEN).value"
              class="form-control"
              name="waschen_putzen_buegeln"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-einkaufen"
            :label="$t('care4YouApp.fragebogen.fields.einkaufen')"
            content-cols="4"
            label-cols="4"
            label-for="einkaufen"
            :style="getLabelStyleByValidateValue(QUESTION_HAUSHALT_EINKAUFEN)"
          >
            <input
              id="einkaufen"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_EINKAUFEN).value"
              class="form-control"
              name="einkaufen"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-reinigen"
            :label="$t('care4YouApp.fragebogen.fields.reinigen')"
            content-cols="4"
            label-cols="4"
            label-for="reinigen"
          >
            <b-form-input
              id="reinigen"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_REINIGEN).value"
              class="form-control"
              name="reinigen"
              :state="validateValue(QUESTION_HAUSHALT_REINIGEN)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="number"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-haustiere"
            :label="$t('care4YouApp.fragebogen.fields.haustiere') + getObligatorySign()"
            content-cols="4"
            label-cols="4"
            label-for="haustiere"
          >
            <b-form-input
              id="haustiere"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_HAUSTIERE).value"
              class="form-control"
              name="haustiere"
              :state="validateValue(QUESTION_HAUSHALT_HAUSTIERE)"
              type="text"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-haushaltstaetigkeiten_sonstiges"
            :label="$t('care4YouApp.fragebogen.fields.haushaltstaetigkeiten_sonstiges')"
            content-cols="4"
            label-cols="4"
            label-for="haushaltstaetigkeiten_sonstiges"
          >
            <b-form-input
              id="haushaltstaetigkeiten_sonstiges"
              v-model="getQuestion(attributes, QUESTION_HAUSHALT_SONSTIGES).value"
              class="form-control"
              name="haushaltstaetigkeiten_sonstiges"
              :state="validateValue(QUESTION_HAUSHALT_SONSTIGES)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <h2 v-text="$t('care4YouApp.fragebogen.sections.livingSpace')">Wohnraum für die Betreuungskraft</h2>
      <div class="row">
        <div class="col-12 border border-dark">
          <b-form-group
            id="fieldset-eigenes_zimmer"
            :label="$t('care4YouApp.fragebogen.fields.eigenes_zimmer')"
            content-cols="4"
            label-cols="4"
            label-for="eigenes_zimmer"
            :style="getLabelStyleByValidateValue(QUESTION_WOHNRAUM_EIGENESZIMMER)"
          >
            <input
              id="eigenes_zimmer"
              v-model="getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESZIMMER).value"
              class="form-control"
              name="eigenes_zimmer"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-eigenes_bett_schrank"
            :label="$t('care4YouApp.fragebogen.fields.eigenes_bett_schrank')"
            content-cols="4"
            label-cols="4"
            label-for="eigenes_bett_schrank"
            :style="getLabelStyleByValidateValue(QUESTION_WOHNRAUM_EIGENESBETT)"
          >
            <input
              id="eigenes_bett_schrank"
              v-model="getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESBETT).value"
              class="form-control"
              name="eigenes_bett_schrank"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-eigenes_bad"
            :label="$t('care4YouApp.fragebogen.fields.eigenes_bad')"
            content-cols="4"
            label-cols="4"
            label-for="eigenes_bad"
            :style="getLabelStyleByValidateValue(QUESTION_WOHNRAUM_EIGENESBAD)"
          >
            <input
              id="eigenes_bad"
              v-model="getQuestion(attributes, QUESTION_WOHNRAUM_EIGENESBAD).value"
              class="form-control"
              name="eigenes_bad"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-internet"
            :label="$t('care4YouApp.fragebogen.fields.internet')"
            content-cols="4"
            label-cols="4"
            label-for="internet"
            :style="getLabelStyleByValidateValue(QUESTION_WOHNRAUM_INTERNET)"
          >
            <input
              id="internet"
              v-model="getQuestion(attributes, QUESTION_WOHNRAUM_INTERNET).value"
              class="form-control"
              name="internet"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <h2 v-text="$t('care4YouApp.fragebogen.sections.requirements')">Anforderung an die Betreuungskraft</h2>
      <div class="row">
        <div class="col-3 border border-dark">
          <b-form-group
            id="fieldset-geschlecht_maennlich"
            :label="$t('care4YouApp.fragebogen.fields.geschlecht_maennlich')"
            content-cols="6"
            label-cols="6"
            label-for="geschlecht_maennlich"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_GESCHLECHT)"
          >
            <input
              id="geschlecht_maennlich"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value"
              class="form-control"
              name="geschlecht"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="maennlich"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-geschlecht_weiblich"
            :label="$t('care4YouApp.fragebogen.fields.geschlecht_weiblich')"
            content-cols="6"
            label-cols="6"
            label-for="geschlecht_weiblich"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_GESCHLECHT)"
          >
            <input
              id="geschlecht_weiblich"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value"
              class="form-control"
              name="geschlecht"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="weiblich"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-geschlecht_egal"
            :label="$t('care4YouApp.fragebogen.fields.geschlecht_egal')"
            content-cols="6"
            label-cols="6"
            label-for="geschlecht_egal"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_GESCHLECHT)"
          >
            <input
              id="geschlecht_egal"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_GESCHLECHT).value"
              class="form-control"
              name="geschlecht"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="radio"
              value="egal"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-3 border border-dark border-left-0">
          <b-form-group
            id="fieldset-alter30"
            :label="$t('care4YouApp.fragebogen.fields.alter30')"
            content-cols="6"
            label-cols="6"
            label-for="alter30"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_30BIS40)"
          >
            <input
              id="alter30"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_30BIS40).value"
              class="form-control"
              name="alter30"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-alter40"
            :label="$t('care4YouApp.fragebogen.fields.alter40')"
            content-cols="6"
            label-cols="6"
            label-for="alter40"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_40BIS50)"
          >
            <input
              id="alter40"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_40BIS50).value"
              class="form-control"
              name="alter40"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-alter50"
            :label="$t('care4YouApp.fragebogen.fields.alter50')"
            content-cols="6"
            label-cols="6"
            label-for="alter50"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_AB50)"
          >
            <input
              id="alter50"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_AB50).value"
              class="form-control"
              name="alter50"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-3 border border-dark border-left-0">
          <b-form-group
            id="fieldset-deutsch_ausreichend"
            :label="$t('care4YouApp.fragebogen.fields.deutsch_ausreichend')"
            content-cols="6"
            label-cols="6"
            label-for="deutsch_ausreichend"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND)"
          >
            <input
              id="deutsch_ausreichend"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_DEUTSCHAUSREICHEND).value"
              class="form-control"
              name="deutsch_ausreichend"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-deutsch_gut"
            :label="$t('care4YouApp.fragebogen.fields.deutsch_gut')"
            content-cols="6"
            label-cols="6"
            label-for="deutsch_gut"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_DEUTSCHGUT)"
          >
            <input
              id="deutsch_gut"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_DEUTSCHGUT).value"
              class="form-control"
              name="deutsch_gut"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
        <div class="col-3 border border-dark border-left-0">
          <b-form-group
            id="fieldset-raucher"
            :label="$t('care4YouApp.fragebogen.fields.raucher')"
            content-cols="6"
            label-cols="6"
            label-for="raucher"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_RAUCHER)"
          >
            <input
              id="raucher"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_RAUCHER).value"
              class="form-control"
              name="raucher"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-nichtraucher"
            :label="$t('care4YouApp.fragebogen.fields.nichtraucher')"
            content-cols="6"
            label-cols="6"
            label-for="nichtraucher"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_NICHTRAUCHER)"
          >
            <input
              id="nichtraucher"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_NICHTRAUCHER).value"
              class="form-control"
              name="nichtraucher"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-raucher_egal"
            :label="$t('care4YouApp.fragebogen.fields.raucher_egal')"
            content-cols="6"
            label-cols="6"
            label-for="raucher_egal"
            :style="getLabelStyleByValidateValue(QUESTION_ANFORDERUNGEN_RAUCHEREGAL)"
          >
            <input
              id="raucher_egal"
              v-model="getQuestion(attributes, QUESTION_ANFORDERUNGEN_RAUCHEREGAL).value"
              class="form-control"
              name="raucher_egal"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="checkbox"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <h2 v-text="$t('care4YouApp.fragebogen.sections.recommendation')">Wer hat Ihnen die Betreuungsagentur empfohlen</h2>
      <div class="row">
        <div class="col-12 border border-dark">
          <b-form-group
            id="fieldset-empfehlung_pflegedienst"
            :label="$t('care4YouApp.fragebogen.fields.empfehlung_pflegedienst')"
            content-cols="10"
            label-cols="2"
            label-for="empfehlung_pflegedienst"
          >
            <b-form-input
              id="empfehlung_pflegedienst"
              v-model="getQuestion(attributes, QUESTION_EMPFEHLUNG_PFLEGEDIENST).value"
              class="form-control"
              name="empfehlung_pflegedienst"
              :state="validateValue(QUESTION_EMPFEHLUNG_PFLEGEDIENST)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-empfehlung_krankenhaus"
            :label="$t('care4YouApp.fragebogen.fields.empfehlung_krankenhaus')"
            content-cols="10"
            label-cols="2"
            label-for="empfehlung_krankenhaus"
          >
            <b-form-input
              id="empfehlung_krankenhaus"
              v-model="getQuestion(attributes, QUESTION_EMPFEHLUNG_KRANKENHAUS).value"
              class="form-control"
              name="empfehlung_krankenhaus"
              :state="validateValue(QUESTION_EMPFEHLUNG_KRANKENHAUS)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-empfehlung_sonstige"
            :label="$t('care4YouApp.fragebogen.fields.empfehlung_sonstige')"
            content-cols="10"
            label-cols="2"
            label-for="empfehlung_sonstige"
          >
            <b-form-input
              id="empfehlung_sonstige"
              v-model="getQuestion(attributes, QUESTION_EMPFEHLUNG_SONSTIGE).value"
              class="form-control"
              name="empfehlung_sonstige"
              :state="validateValue(QUESTION_EMPFEHLUNG_SONSTIGE)"
              style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
              type="text"
              :disabled="readonlyMode"
            />
          </b-form-group>
        </div>
      </div>

      <br />
      <br />
      <h3 class="text-center" v-text="$t('care4YouApp.fragebogen.sections.dataPrivacy')"></h3>
      <h5 class="text-center" v-text="$t('care4YouApp.fragebogen.additions.dataPrivacy')"></h5>
      <br />

      <div class="row">
        <div class="col-1">
          <input
            id="datenschutz_akzeptiert"
            v-model="getQuestion(attributes, QUESTION_DATENSCHUTZ_AKZEPTIERT).value"
            class="form-control"
            name="datenschutz_akzeptiert"
            :state="validateValue(QUESTION_DATENSCHUTZ_AKZEPTIERT)"
            style="height: 1.5rem; margin: 1rem 0rem 0rem 0rem"
            type="checkbox"
            :disabled="readonlyMode"
          />
        </div>
        <div class="col-11">
          <h5
            :style="getLabelStyleByValidateValue(QUESTION_DATENSCHUTZ_AKZEPTIERT)"
            v-text="$t('care4YouApp.fragebogen.fields.datenschutz_ueberschrift') + getObligatorySign()"
          ></h5>
          <p class="mb-0" v-text="$t('care4YouApp.fragebogen.fields.datenschutz_text')"></p>
          <p><a :href="agencyWebsite" target="_blank" v-text="agencyWebsite"></a></p>
        </div>
      </div>

      <b-button
        v-if="isSaveButtonVisible()"
        id="submitFragebogen"
        :disabled="getQuestion(attributes, QUESTION_DATENSCHUTZ_AKZEPTIERT).value != true || isSaving"
        class="btn btn-primary"
        @click="submitFragebogen()"
      >
        <font-awesome-icon icon="save"></font-awesome-icon>
        <span class="d-none d-md-inline" v-text="$t('entity.action.save')">Save</span>
      </b-button>
    </form>
  </div>
</template>
<script lang="ts" src="./fragebogen.component.ts"></script>
<style scoped>
.fragebogen-form input[disabled] {
  color: #666;
}
input[type='checkbox'],
input[type='radio'] {
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
