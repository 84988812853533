<template>
  <b-navbar class="cfooter bg-dark" data-cy="navbar" toggleable="md" type="dark">
    <b-collapse id="header-tabs" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          <span v-text="$t('footer.copyright')">© app:team:al</span>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item exact to="/impressum">
          <span>
            <font-awesome-icon icon="section" />
            <span v-text="$t('global.menu.impressum')">Impressum</span>
          </span>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts" src="./jhi-footer.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cfooter {
  height: 50px;
}
</style>
