<template>
  <b-navbar class="bg-dark" data-cy="navbar" toggleable="md" type="dark">
    <b-navbar-brand b-link class="logo" to="/">
      <span class="logo-img"></span>
    </b-navbar-brand>
    <b-navbar-brand b-link class="version" to="/changelog">
      <span class="navbar-version">{{ version }}</span>
    </b-navbar-brand>
    <b-navbar-toggle
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="jh-navbar-toggler d-lg-none"
      data-toggle="collapse"
      href="javascript:void(0);"
      right
      target="header-tabs"
    >
      <font-awesome-icon icon="bars" />
    </b-navbar-toggle>

    <b-collapse id="header-tabs" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item exact to="/">
          <span>
            <font-awesome-icon icon="home" />
            <span v-text="$t('global.menu.home')">Home</span>
          </span>
        </b-nav-item>

        <b-nav-item v-if="authenticated" to="/postbox">
          <span>
            <font-awesome-icon icon="list-check" />
            <span v-text="$t('global.menu.postbox')">Postbox</span>
          </span>
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="authenticated"
          id="new-customer-menu"
          :class="{ 'router-link-active': subIsActive('/customer-create') }"
          active-class="active"
          class="pointer"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="user-plus" />
            <span class="no-bold" v-text="$t('global.menu.customerCreate.main')">New Customer</span>
          </span>
          <b-dropdown-item active-class="active" to="/customer-create/withProcess">
            <font-awesome-icon icon="user-plus" />
            <span v-text="$t('global.menu.customerCreate.withProcess')">New Customer With Process</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/customer-create">
            <font-awesome-icon icon="user-plus" />
            <span v-text="$t('global.menu.customerCreate.withoutProcess')">New Customer Without Process</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/customer-create/onlineQuestionnaire">
            <font-awesome-icon icon="user-plus" />
            <span v-text="$t('global.menu.customerCreate.onlineQuestionnaire')">Create Questionnaire</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-if="authenticated" :to="{ name: 'CarerOverview' }">
          <span>
            <font-awesome-icon icon="user-nurse" />
            <span v-text="$t('global.menu.carer-overview')">Carer-Overview</span>
          </span>
        </b-nav-item>

        <b-nav-item v-if="authenticated" :to="{ name: 'CustomerOverview' }">
          <span>
            <font-awesome-icon icon="users" />
            <span v-text="$t('global.menu.customer-overview')">Customer-Overview</span>
          </span>
        </b-nav-item>

        <b-nav-item v-if="hasAnyAuthority('ROLE_MANAGER') && authenticated" :to="{ name: 'AgencyOverview' }">
          <span>
            <font-awesome-icon icon="building" />
            <span v-text="$t('global.menu.agency-overview')">Agency-Overview</span>
          </span>
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="hasAnyAuthority('ROLE_MANAGER') && authenticated"
          id="statistic-menu"
          :class="{ 'router-link-active': subIsActive('/statistics') }"
          active-class="active"
          class="pointer"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="chart-pie" />
            <span class="no-bold" v-text="$t('global.menu.statistic.main')">Statistic</span>
          </span>
          <b-dropdown-item active-class="active" to="/statistics/month-sales">
            <font-awesome-icon icon="euro-sign" />
            <span v-text="$t('global.menu.statistic.monthSales')">Month Sales</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown v-if="hasAnyAuthority('ROLE_ADMIN')" id="admin-menu" active-class="active" class="pointer" right>
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="users-cog" />
            <span class="no-bold" v-text="$t('global.menu.tools')">Administration</span>
          </span>
          <b-dropdown-item v-if="authenticated" v-on:click="scheduleCheckInvoices()">
            <span>
              <font-awesome-icon icon="list-check" />
              <span v-text="$t('global.menu.check-invoices')">Check Invoices2</span>
            </span>
          </b-dropdown-item>

          <b-dropdown-item v-if="authenticated" v-on:click="scheduleCheckInvoicePayments()">
            <span>
              <font-awesome-icon icon="list-check" />
              <span v-text="$t('global.menu.check-invoice-payments')">Check Invoice Payments</span>
            </span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated"
          id="entity-menu"
          active-class="active"
          class="pointer"
          data-cy="entity"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="th-list" />
            <span class="no-bold" v-text="$t('global.menu.entities.main')">Entities</span>
          </span>
          <entities-menu></entities-menu>
          <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated"
          id="admin-menu"
          :class="{ 'router-link-active': subIsActive('/admin') }"
          active-class="active"
          class="pointer"
          data-cy="adminMenu"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="users-cog" />
            <span class="no-bold" v-text="$t('global.menu.admin.main')">Administration</span>
          </span>
          <b-dropdown-item active-class="active" to="/admin/metrics">
            <font-awesome-icon icon="tachometer-alt" />
            <span v-text="$t('global.menu.admin.metrics')">Metrics</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/health">
            <font-awesome-icon icon="heart" />
            <span v-text="$t('global.menu.admin.health')">Health</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/configuration">
            <font-awesome-icon icon="cogs" />
            <span v-text="$t('global.menu.admin.configuration')">Configuration</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/logs">
            <font-awesome-icon icon="tasks" />
            <span v-text="$t('global.menu.admin.logs')">Logs</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="openAPIEnabled" active-class="active" to="/admin/docs">
            <font-awesome-icon icon="book" />
            <span v-text="$t('global.menu.admin.apidocs')">API</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="languages && Object.keys(languages).length > 1" id="languagesnavBarDropdown" right>
          <span slot="button-content">
            <font-awesome-icon icon="flag" />
            <span class="no-bold" v-text="$t('global.menu.language')">Language</span>
          </span>
          <b-dropdown-item
            v-for="(value, key) in languages"
            :key="`lang-${key}`"
            :class="{ active: isActiveLanguage(key) }"
            v-on:click="changeLanguage(key)"
          >
            {{ value.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-if="authenticated" :to="{ name: 'NotificationOverview' }">
          <span :title="$t('global.menu.notification-overview')">
            <font-awesome-icon icon="bell" />
            <b-badge :variant="notificationCount > 0 ? 'primary' : 'light'">{{ notificationCount }}</b-badge>
          </span>
        </b-nav-item>

        <b-nav-item-dropdown
          id="account-menu"
          :class="{ 'router-link-active': subIsActive('/account') }"
          active-class="active"
          class="pointer"
          data-cy="accountMenu"
          href="javascript:void(0);"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="user" />
            <span class="no-bold" v-text="username"> Account </span>
          </span>
          <b-dropdown-item
            v-if="authenticated && hasAnyAuthority('ROLE_USER')"
            id="user-settings"
            active-class="active"
            data-cy="user-settings"
            to="/account/settings/0"
          >
            <font-awesome-icon icon="gear" />
            <span v-text="$t('global.menu.account.settings')">Settings</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="authenticated" id="logout" active-class="active" data-cy="logout" v-on:click="logout()">
            <font-awesome-icon icon="sign-out-alt" />
            <span v-text="$t('global.menu.account.logout')">Sign out</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="!authenticated" id="login" active-class="active" data-cy="login" v-on:click="openLogin()">
            <font-awesome-icon icon="sign-in-alt" />
            <span v-text="$t('global.menu.account.login')">Sign in</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts" src="./jhi-navbar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */
.navbar-version {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 0px 5px 15px;
  margin-right: 0px;
}

.navbar-brand.version {
  padding: 5px 15px 5px 0px;
}

.logo .logo-img {
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 75px;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/logo-no-background_white.svg') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}
</style>
